<template functional>
  <div
    class="full-size is-flex is-flex-direction-column is-align-items-center is-justify-content-center not-found is-size-1"
  >
    <div>
      <component :is="$options.components.NotFoundAnimated" />
      <p v-if="props.detail">{{ props.detail }}</p>
    </div>
    <div>
      <p class="not-found-message" v-html="parent.$t('messageError.notFound')"></p>
    </div>
    <div class="go-back">
      <router-link :to="{ name: 'home' }" class="is-flex is-align-items-center">
        <b-icon icon="arrow-left"> </b-icon>{{ parent.$t('messageError.goBack') }}</router-link
      >
    </div>
  </div>
</template>

<script>
import NotFoundAnimated from '@/components/NotFoundAnimated.vue'
export default {
  components: {
    NotFoundAnimated
  }
}
</script>

<style scoped>
.full-size {
  height: 100vh;
}
.not-found-message {
  font-size: 3vh;
}
.go-back {
  margin-top: 3vh;
  font-size: 2.5vh;
}
</style>
